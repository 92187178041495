<template>
  <div class="app-documents">
    <page-header
      title="Documents"
      description="Gestion des documents"
      :button="{
        text: 'Ajouter un fichier',
        icon: 'fas fa-plus-circle',
        action: 'add'
      }"
      @action="addDocument"
    ></page-header>
    <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
      <div class="rounded mx-5 ring-1 ring-black ring-opacity-5 mt-10">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" style="width: 20%;" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Title
              </th>
              <th scope="col" style="width: 40%;" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Description
              </th>
              <th scope="col" style="width: 20%;" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" >File name</th>
              <th scope="col" style="width: 5%;" class="px-3 py-3.5 text-sm font-semibold text-gray-900" align="center">Size</th>
              <th scope="col" style="width: 5%;" class="px-3 py-3.5 text-sm font-semibold text-gray-900" align="center">Aperçu</th>
              <th scope="col" style="width: 10%;" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Publication Date
              </th>
              <th scope="col" style="width: 5%;" align="center" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                Actions
                <span class="sr-only">Edit</span>
                <span class="sr-only">Delete</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white" v-if="documents.length">
            <tr
              class="hover:bg-primary-100"
              v-for="(document, docId) in filteredDocuments"
              :key="docId"
              :class="docId % 2 === 0 ? undefined : 'bg-gray-50'"
            >
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ document.title }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ document.description }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span v-if="document.files.length">{{ document.files[0].name }}</span>
                <span v-else>Pas de document</span>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ document.files[0].size | fileSize}}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500" align="center">
                <a v-bind:href="document.files[0].url" target="_blank" v-if="document.files.length"><i class="fas fa-eye"></i></a>
                <span v-else>/</span>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ getFormatDate(document.publication_date) }}</td>
              <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-center gap-4 items-center">
                <div class="text-indigo-600 hover:text-indigo-900 cursor-pointer" v-on:click="editDoc(document, docId)"><i class="fas fa-pen"></i></div>
                <div class="text-indigo-600 hover:text-indigo-900 cursor-pointer" v-on:click="deleteDoc(document)"><i class="fas fa-trash"></i></div>
              </td>
            </tr>
          </tbody>
          <tfoot v-else>
            <tr @click="addDocument">
              <td colspan="6" align="center" class="align-middle py-4 font-bold cursor-pointer hover:bg-primary-500 hover:text-white">Aucun document n'a été trouvé, cliquez ici pour en ajouter</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import { http } from '@/services/api.service'
import PageHeader from '@/components/PageHeader'
import OneDocument from './Modals/One'
import { orderBy, reverse } from 'lodash'

export default {
  components: { PageHeader },
  filters: {
    fileSize: function (num) {
      var base = 1024
      var prefixes = ['k', 'M', 'G', 'T']
      var exp = Math.log(num) / Math.log(base) | 0
      return (num / Math.pow(base, exp)).toFixed(1) + ' ' + ((exp > 0) ? prefixes[ exp - 1 ] + 'o' : 'Bytes')
    }
  },
  data () {
    return {
      loading: false,
      documents: []
    }
  },
  computed: {
    // ...mapGetters([
    //   'documents'
    // ])
    filteredDocuments () {
      if (this.sortBy) {
        if (this.reverse) {
          return reverse(orderBy(this.documents, doc => {
            return doc[this.sortByLabel]
          }))
        }
        return orderBy(this.documents, doc => {
          return doc[this.sortByLabel]
        })
      }
      return this.documents
    }
  },
  mounted () {
    this.getDocuments()
  },
  methods: {
    getFormatDate (date) {
      return new Date(date).toLocaleString('fr-FR', { timeZone: 'UTC' })
    },
    getDocuments () {
      this.loading = true
      http.get('/documents').then(res => {
        this.loading = false
        this.documents = res.data
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des documents',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    addDocument () {
      this.$buefy.modal.open({
        parent: this,
        component: OneDocument,
        hasModalCard: true,
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          created: (newDoc) => {
            this.documents.push(newDoc)
            this.$buefy.notification.open({
              duration: 3000,
              message: `Le document <strong>${newDoc.title}</strong> a bien été créé`,
              position: 'is-bottom-right',
              type: 'is-success',
              hasIcon: false
            })
          }
        }
      })
    },
    editDoc (document, docPosition) {
      this.$buefy.modal.open({
        parent: this,
        component: OneDocument,
        hasModalCard: true,
        props: { currentDoc: document },
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          updated: (updatedDoc) => {
            this.documents[docPosition] = updatedDoc
            this.documents.splice(docPosition, 1, updatedDoc)
            this.$buefy.notification.open({
              duration: 3000,
              message: `Le document <strong>${updatedDoc.title}</strong> a bien été modifié`,
              position: 'is-bottom-right',
              type: 'is-success',
              hasIcon: false
            })
          }
        }
      })
    },
    deleteDoc (document) {
      if (confirm(`Êtes-vous sûr de vouloir supprimer ce document : ${document.title} ?`)) {
        this.loading = true
        http.delete(`/documents/${document.uuid}`).then(res => {
          this.loading = false
          this.documents = this.documents.filter(doc => {
            return doc.uuid !== document.uuid
          })
          this.$buefy.notification.open({
            duration: 3000,
            message: `Le document <strong>${document.title}</strong> a bien été supprimé`,
            position: 'is-bottom-right',
            type: 'is-success',
            hasIcon: false
          })
        })
      } else {
        this.$buefy.notification.open({
          duration: 3000,
          message: `Annulation de la suppression du document <strong>${document.title}</strong>`,
          position: 'is-bottom-right',
          type: 'is-warning',
          hasIcon: false
        })
      }
    }
  }
}
</script>
