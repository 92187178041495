<template>
    <div class="bg-white modal-card rounded">
        <form>
            <div class="bg-primary-100 p-4 text-3xl">{{modalTitle}}</div>
            <div class="p-4">
                <div class="flex flex-row gap-3">
                    <div class="flex flex-col flex-1">
                        <label for="title">Titre</label>
                        <input class="rounded" placeholder="Titre du document" v-model="docForm.title" type="text" id="title">
                    </div>
                    <div class="flex flex-col">
                        <label for="date">Date de publication</label>
                        <input class="rounded" v-model="date" type="date" id="date">
                    </div>
                    <div class="flex flex-col">
                        <label for="time">Heure de publication</label>
                        <input class="rounded" v-model="time" type="time" id="time">
                    </div>
                </div>
                <div class="flex flex-col mt-2">
                    <label for="description">Description</label>
                    <input class="rounded" placeholder="Description du document" v-model="docForm.description" type="text" id="description">
                </div>
                <div class="flex items-center gap-3 mt-2">
                    <b-upload v-model="file" @input="addFile">
                      <a class="button" v-bind:class="fileError ? 'is-danger' : 'is-primary'">
                        <b-icon icon="upload"></b-icon>
                        <span v-if="!file.name && !currentDoc">Ajouter un document</span>
                        <span v-else>Modifier le document</span>
                      </a>
                    </b-upload>
                    <div class="field is-horizontal">
                      <div class="field-label"></div>
                      <div class="field-body">
                        <ul class="documents-list">
                          <li>
                            <div class="columns">
                              <span class="column is-narrow" v-if="file.name && !currentDoc">{{ file.name }}</span>
                              <span class="column is-narrow" v-else-if="!file.name && currentDoc">
                                <span v-if="currentDoc.files.length">{{ currentDoc.files[0].name }}</span>
                                <span v-else>/</span>
                              </span>
                              <span class="column is-narrow" v-else>{{ file.name }}</span>
                              <span class="column is-narrow font-bold text-red-500" v-if="fileError">{{ fileError }}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                </div>
            </div>
            <div class="bg-primary-100 p-4 flex justify-end gap-3">
                <button type="button" @click="$parent.close()" class="border-2 border-grey-300 px-3 py-2 rounded">Annuler</button>
                <button type="button" @click="sendFile()" class="bg-primary-500 text-white hover:bg-primary-600 px-3 py-2 rounded">Envoyer</button>
            </div>
        </form>
    </div>
</template>

<script>
import { http } from '@/services/api.service'
// import { timeStamp } from 'console'
import { format } from 'date-fns'

export default {
  name: 'app-surveys-one',
  props: ['currentDoc'],
  data () {
    return {
      docForm: {
        title: '',
        description: ''
      },
      date: new Date().toJSON().slice(0, 10),
      time: new Date().getHours() + ':' + new Date().getMinutes(),
      files: [],
      file: {},
      modalTitle: `Création d'un document`,
      fileError: null
    }
  },
  beforeMount () {
    if (this.currentDoc) {
      this.docForm.title = this.currentDoc.title
      this.docForm.description = this.currentDoc.description
      this.date = this.currentDoc.publication_date.slice(0, 10)
      this.time = this.currentDoc.publication_date.slice(11, 16)
      this.modalTitle = `Modification d'un document`
    }
  },
  methods: {
    fileSize (size) {
      if (size) {
        const sizes = ['octets', 'ko', 'Mo', 'Go']
        const i = Math.floor(Math.log(size) / Math.log(1024))
        return `${parseFloat((size / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`
      } else {
        return `O o`
      }
    },
    sendFile () {
      let formData = this.setFormData()
      if (this.currentDoc) {
        http.post(`/documents/${this.currentDoc.uuid}`, formData, {
          headers: {
            'Content-Type': `multipart/form-data`
          }
        }).then(({ data }) => {
          this.$emit('updated', data)
        }).catch(err => {
          console.log({ err })
        }).finally(() => {
          this.$parent.close()
        })
      } else {
        if (this.file.name) {
          http.post('/documents', formData, {
            headers: {
              'Content-Type': `multipart/form-data`
            }
          }).then(({ data }) => {
            this.$emit('created', data)
          }).catch(err => {
            console.log({ err })
          }).finally(() => {
            this.$parent.close()
          })
        } else {
          this.fileError = `Vous devez ajouter un document`
        }
      }
    },
    setFormData () {
      const formData = new FormData()
      const { ...data } = this.docForm
      for (const prop in data) {
        formData.append(prop, data[prop])
      }
      let date = new Date(this.date)
      date.setHours(this.time.split(':')[0])
      date.setMinutes(this.time.split(':')[1])
      formData.append('publication_date', format(date, 'yyyy-LL-dd HH:mm:ss'))

      this.files.map(file => {
        formData.append('files[]', file)
      })

      return formData
    },
    addFile (file) {
      this.fileError = null
      this.files[0] = file
    }
  }
}

</script>
